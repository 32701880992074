html, body, #root {
  height: 100%;
  scroll-behavior: smooth;
}

.App {
  height: 100%;
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {}
